<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Apex College Showcase Participants 2025</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
            <v-text-field
              class="mt-4"
              dense
              v-model="search"
              outlined
              clearable
              label="Search across all sessions by any field"
              hide-details
            ></v-text-field>
            <div class="x-small mt-1 mb-8"><b>You can by:</b> Gymnast Name, Club Name, Year of Graduation, Gymnastics or Acro, etc</div>
          </div>
      </v-col>
    </v-row>
    <!-- Session 1 -->
    <h2 class="mt-8">Session 1 - Acro & Tumbling ({{sessions.session1.length}} athletes)</h2>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="sessions.session1"
          item-value="_id"
          :items-per-page="300"
          class="elevation-0"
          :search="search"
          v-model="selectedRows"
          item-key="_id"
          :mobile-breakpoint="600"
          no-results-text="There are no athletes to display with this search criteria"
          :disable-pagination="true"
          :loading="loading"
        >
          <template v-slot:item.profile_picture_id="row">
            <div class="mini-profile-picture-outer-container">
              <div class="mini-profile-picture-container">
                <img v-if="row.item.profile_picture_id" :src="`https://lh3.googleusercontent.com/d/${row.item.profile_picture_id}=w1000?authuser=1/view`" class="mini-profile-picture"/>
                <img v-else :src="`/fem-placeholder.png`" class="mini-profile-picture"/>
              </div>
            </div>
          </template>          
          <template v-slot:item.athleteGradYear="row">
            <div :class="`athleteGradYearPill y${row.item.athleteGradYear}`">{{row.item.athleteGradYear}}</div>
          </template>          
          <template v-slot:item.athleteDob="row">
            <div>{{row.item.athleteDob}}</div>
            <div class="small">{{getAge(row.item.athleteDob)}}</div>
          </template>
          <template v-slot:item.gymName="row">
            <div>{{row.item.gymName}}</div>
            <div class="small">{{row.item.athleteLevel}}</div>
          </template>
          <template v-slot:item.athleteFirstName="row">
            <div>
              {{row.item.athleteFirstName}} {{row.item.athleteLastName}}
            </div>
            <div class="committed-pill committed" v-if="row.item.athleteCommitted">
              Committed <span v-if="!!row.item.athleteCommittedTo"> to {{row.item.athleteCommittedTo}}</span>
            </div>
            <div class="committed-pill uncommitted" v-else>
              Uncommitted
            </div>
          </template>
          <template v-slot:item.action="row">
            <v-btn dark x-small class="action-btn" color="blue" @click="() => goToProfileForCoach(row.item._id)" left outlined>
              View Athlete Details
            </v-btn>
          </template>
          <template v-slot:item.athleteRegisteredFor="row">
            <div class="pill-reg-for-container" v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - gym hp & a&t') > -1">
              <div class="pill-reg-for pill-gym">Gymnastics Showcase</div> <div class="pill-reg-for pill-at">Acro & Tumbling</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - acro & tumbling plus all levels') > -1">
              <div class="pill-reg-for pill-at">Acro & Tumbling</div> <div class="pill-reg-for pill-al">All Levels Camp</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('gymnastics hp showcase') > -1">
              <div class="pill-reg-for pill-gym">Gymnastics Showcase</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('acro & tumbling') > -1">
              <div class="pill-reg-for pill-at">Acro and Tumbling</div>
            </div>
            <div v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('all levels') > -1">
              <div class="pill-reg-for pill-al">All Levels Camp</div>
            </div>
          </template>
          <template v-slot:item.athleteGPA="row">
              <div class="athlete-gpa">{{row.item.athleteGPA}}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Session 2 -->
    <h2 class="mt-8">Session 2 - Gymnastics Showcase ({{sessions.session2.length}} athletes)</h2>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="sessions.session2"
          item-value="_id"
          :items-per-page="300"
          class="elevation-0"
          :search="search"
          v-model="selectedRows"
          item-key="_id"
          :mobile-breakpoint="600"
          no-results-text="There are no athletes to display with this search criteria"
          :disable-pagination="true"
          :loading="loading"          
        >
          <template v-slot:item.profile_picture_id="row">
            <div class="mini-profile-picture-outer-container">
              <div class="mini-profile-picture-container">
                <img v-if="row.item.profile_picture_id" :src="`https://lh3.googleusercontent.com/d/${row.item.profile_picture_id}=w1000?authuser=1/view`" class="mini-profile-picture"/>
                <img v-else :src="`/fem-placeholder.png`" class="mini-profile-picture"/>
              </div>
            </div>
          </template>   
          <template v-slot:item.athleteGradYear="row">
            <div :class="`athleteGradYearPill y${row.item.athleteGradYear}`">{{row.item.athleteGradYear}}</div>
          </template>   
          <template v-slot:item.athleteDob="row">
            <div>{{row.item.athleteDob}}</div>
            <div class="small">{{getAge(row.item.athleteDob)}}</div>
          </template>
          <template v-slot:item.gymName="row">
            <div>{{row.item.gymName}}</div>
            <div class="small">{{row.item.athleteLevel}}</div>
          </template>                 
          <template v-slot:item.athleteFirstName="row">
            <div>
              {{row.item.athleteFirstName}} {{row.item.athleteLastName}}
            </div>
            <div class="committed-pill committed" v-if="row.item.athleteCommitted">
              Committed <span v-if="!!row.item.athleteCommittedTo"> to {{row.item.athleteCommittedTo}}</span>
            </div>
            <div class="committed-pill uncommitted" v-else>
              Uncommitted
            </div>
          </template>
          <template v-slot:item.action="row">
            <v-btn dark x-small class="action-btn" color="blue" @click="() => goToProfileForCoach(row.item._id)" left outlined>
              View Athlete Details
            </v-btn>
          </template>
          <template v-slot:item.athleteRegisteredFor="row">
            <div class="pill-reg-for-container" v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - gym hp & a&t') > -1">
              <div class="pill-reg-for pill-gym">Gymnastics Showcase</div> <div class="pill-reg-for pill-at">Acro & Tumbling</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - acro & tumbling plus all levels') > -1">
              <div class="pill-reg-for pill-at">Acro & Tumbling</div> <div class="pill-reg-for pill-al">All Levels Camp</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('gymnastics hp showcase') > -1">
              <div class="pill-reg-for pill-gym">Gymnastics Showcase</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('acro & tumbling') > -1">
              <div class="pill-reg-for pill-at">Acro and Tumbling</div>
            </div>
            <div v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('all levels') > -1">
              <div class="pill-reg-for pill-al">All Levels Camp</div>
            </div>
          </template>
          <template v-slot:item.athleteGPA="row">
              <div class="athlete-gpa">{{row.item.athleteGPA}}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Session 3 -->
    <h2 class="mt-8">Session 3 - Gymnastics Showcase ({{sessions.session3.length}} athletes)</h2>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="sessions.session3"
          item-value="_id"
          :items-per-page="300"
          class="elevation-0"
          :search="search"
          v-model="selectedRows"
          item-key="_id"
          :mobile-breakpoint="600"
          no-results-text="There are no athletes to display with this search criteria"
          :disable-pagination="true"
          :loading="loading"          
        >
          <template v-slot:item.profile_picture_id="row">
            <div class="mini-profile-picture-outer-container">
              <div class="mini-profile-picture-container">
                <img v-if="row.item.profile_picture_id" :src="`https://lh3.googleusercontent.com/d/${row.item.profile_picture_id}=w1000?authuser=1/view`" class="mini-profile-picture"/>
                <img v-else :src="`/fem-placeholder.png`" class="mini-profile-picture"/>
              </div>
            </div>
          </template>             
          <template v-slot:item.athleteGradYear="row">
            <div :class="`athleteGradYearPill y${row.item.athleteGradYear}`">{{row.item.athleteGradYear}}</div>
          </template>
          <template v-slot:item.athleteDob="row">
            <div>{{row.item.athleteDob}}</div>
            <div class="small">{{getAge(row.item.athleteDob)}}</div>
          </template>
          <template v-slot:item.gymName="row">
            <div>{{row.item.gymName}}</div>
            <div class="small">{{row.item.athleteLevel}}</div>
          </template>          
          <template v-slot:item.athleteFirstName="row">
            <div>
              {{row.item.athleteFirstName}} {{row.item.athleteLastName}}
            </div>
            <div class="committed-pill committed" v-if="row.item.athleteCommitted">
              Committed <span v-if="!!row.item.athleteCommittedTo"> to {{row.item.athleteCommittedTo}}</span>
            </div>
            <div class="committed-pill uncommitted" v-else>
              Uncommitted
            </div>
          </template>
          <template v-slot:item.action="row">
            <v-btn dark x-small class="action-btn" color="blue" @click="() => goToProfileForCoach(row.item._id)" left outlined>
              View Athlete Details
            </v-btn>
          </template>
          <template v-slot:item.athleteRegisteredFor="row">
            <div class="pill-reg-for-container" v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - gym hp & a&t') > -1">
              <div class="pill-reg-for pill-gym">Gymnastics Showcase</div> <div class="pill-reg-for pill-at">Acro & Tumbling</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - acro & tumbling plus all levels') > -1">
              <div class="pill-reg-for pill-at">Acro & Tumbling</div> <div class="pill-reg-for pill-al">All Levels Camp</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('gymnastics hp showcase') > -1">
              <div class="pill-reg-for pill-gym">Gymnastics Showcase</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('acro & tumbling') > -1">
              <div class="pill-reg-for pill-at">Acro and Tumbling</div>
            </div>
            <div v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('all levels') > -1">
              <div class="pill-reg-for pill-al">All Levels Camp</div>
            </div>
          </template>
          <template v-slot:item.athleteGPA="row">
              <div class="athlete-gpa">{{row.item.athleteGPA}}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Session 4 -->
    <h2 class="mt-8">Session 4 - Gymnastics All Levels Camp ({{sessions.session4.length}} athletes)</h2>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="sessions.session4"
          item-value="_id"
          :items-per-page="300"
          class="elevation-0"
          :search="search"
          v-model="selectedRows"
          item-key="_id"
          :mobile-breakpoint="600"
          :disable-pagination="true"
          no-results-text="There are no athletes to display with this search criteria"
          :loading="loading"          
        >
          <template v-slot:item.profile_picture_id="row">
            <div class="mini-profile-picture-outer-container">
              <div class="mini-profile-picture-container">
                <img v-if="row.item.profile_picture_id" :src="`https://lh3.googleusercontent.com/d/${row.item.profile_picture_id}=w1000?authuser=1/view`" class="mini-profile-picture"/>
                <img v-else :src="`/fem-placeholder.png`" class="mini-profile-picture"/>
              </div>
            </div>
          </template>             
          <template v-slot:item.athleteGradYear="row">
            <div :class="`athleteGradYearPill y${row.item.athleteGradYear}`">{{row.item.athleteGradYear}}</div>
          </template>
          <template v-slot:item.athleteDob="row">
            <div>{{row.item.athleteDob}}</div>
            <div class="small">{{getAge(row.item.athleteDob)}}</div>
          </template>
          <template v-slot:item.gymName="row">
            <div>{{row.item.gymName}}</div>
            <div class="small">{{row.item.athleteLevel}}</div>
          </template>          
          <template v-slot:item.athleteFirstName="row">
            <div>
              {{row.item.athleteFirstName}} {{row.item.athleteLastName}}
            </div>
            <div class="committed-pill committed" v-if="row.item.athleteCommitted">
              Committed <span v-if="!!row.item.athleteCommittedTo"> to {{row.item.athleteCommittedTo}}</span>
            </div>
            <div class="committed-pill uncommitted" v-else>
              Uncommitted
            </div>
          </template>
          <template v-slot:item.action="row">
            <v-btn dark x-small class="action-btn" color="blue" @click="() => goToProfileForCoach(row.item._id)" left outlined>
              View Athlete Details
            </v-btn>
          </template>
          <template v-slot:item.athleteRegisteredFor="row">
            <div class="pill-reg-for-container" v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - gym hp & a&t') > -1">
              <div class="pill-reg-for pill-gym">Gymnastics Showcase</div> <div class="pill-reg-for pill-at">Acro & Tumbling</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - acro & tumbling plus all levels') > -1">
              <div class="pill-reg-for pill-at">Acro & Tumbling</div> <div class="pill-reg-for pill-al">All Levels Camp</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('gymnastics hp showcase') > -1">
              <div class="pill-reg-for pill-gym">Gymnastics Showcase</div>
            </div>
            <div class="pill-reg-for-container"  v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('acro & tumbling') > -1">
              <div class="pill-reg-for pill-at">Acro and Tumbling</div>
            </div>
            <div v-else-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('all levels') > -1">
              <div class="pill-reg-for pill-al">All Levels Camp</div>
            </div>
          </template>
          <template v-slot:item.athleteGPA="row">
              <div class="athlete-gpa">{{row.item.athleteGPA}}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {},
  data() {
    return {
      athlete: [],
      search:'',
      expanded: [0],
      selectedRows:[],
      copyParentEmail: false, 
      copyGymnastEmail: false, 
      copyCoachEmail: false, 
      dialog: false,
      snackbar: false,
      singleExpand: false,
      loading: false,
        headers:[
        {
          text: '',
          align: 'left',
          value: 'profile_picture_id',
        },
        {
          text: 'Grad Year',
          align: 'left',
          value: 'athleteGradYear',
        },
        {
          text: 'Date of Birth',
          align: 'center',
          value: 'athleteDob',
        },
        {
          text: 'Full Name',
          align: 'start',
          value: 'athleteFirstName',
        },
        {
          text: 'Last Name',
          align: ' d-none',
          value: 'athleteLastName',
        },
        {
          text: 'Gym',
          align: 'start',
          value: 'gymName',
        },
        {
          text: 'Registered for',
          value: 'athleteRegisteredFor',
        },
        {
          text: 'GPA',
          align: 'center',
          value: 'athleteGPA',
        },
        {
          text: 'Action',
          align: 'start',
          value: "action"
        },
               
      ],

    }
  },
  async mounted() {
    await this.getAthletes();
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getAge(dateString) {
      const now = new Date();
      const today = new Date(now.getYear(),now.getMonth(),now.getDate());

      const yearNow = now.getYear();
      const monthNow = now.getMonth();
      const dateNow = now.getDate();

      let y = dateString.substring(6,10);
          y = y.length < 4 ? '20'+y : y;

      const dob = new Date(y,
                        dateString.substring(0,2)-1,                   
                        dateString.substring(3,5)                  
                        );

      const yearDob = dob.getYear();
      const monthDob = dob.getMonth();
      const dateDob = dob.getDate();
      let age = {};
      let ageString = "";
      let yearString = "";
      let monthString = "";
      let dayString = "";


      let yearAge = yearNow - yearDob;
      let monthAge;
      if (monthNow >= monthDob)
        monthAge = monthNow - monthDob;
      else {
        yearAge--;
        monthAge = 12 + monthNow -monthDob;
      }
      let dateAge;
      if (dateNow >= dateDob)
        dateAge = dateNow - dateDob;
      else {
        monthAge--;
        dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
          years: yearAge,
          months: monthAge,
          days: dateAge
          };

      if ( age.years > 1 ) yearString = this.isMobile() ? "y " : " years ";
      else yearString = this.isMobile() ? "y " : " year ";
      if ( age.months> 1 ) monthString = this.isMobile() ? "m " : " months ";
      else monthString =  this.isMobile() ? "m " : " month ";
      if ( age.days > 1 ) dayString = this.isMobile() ? "d" : " days ";
      else dayString =  this.isMobile() ? "d" : " day ";

      const connector =  this.isMobile() ? "" : " and ";
      const postpend =  this.isMobile() ? "" : " old.";
      const happybday =  this.isMobile() ? "H-Bday!" : "  Happy Birthday!!";

      if ( (age.years > 0) && (age.months > 0) && (age.days > 0) )
        ageString = age.years + yearString + age.months + monthString + connector + age.days + dayString + postpend;
      else if ( (age.years == 0) && (age.months == 0) && (age.days > 0) )
        ageString = "Only " + age.days + dayString + postpend;
      else if ( (age.years > 0) && (age.months == 0) && (age.days == 0) )
        ageString = age.years + yearString + postpend + happybday;
      else if ( (age.years > 0) && (age.months > 0) && (age.days == 0) )
        ageString = age.years + yearString + connector + age.months + monthString + postpend;
      else if ( (age.years == 0) && (age.months > 0) && (age.days > 0) )
        ageString = age.months + monthString + connector + age.days + dayString + postpend;
      else if ( (age.years > 0) && (age.months == 0) && (age.days > 0) )
        ageString = age.years + yearString + connector + age.days + dayString + postpend;
      else if ( (age.years == 0) && (age.months > 0) && (age.days == 0) )
        ageString = age.months + monthString + postpend;
      else ageString = "Oops! Could not calculate age!";

      return ageString;
    },

    setSearch(val) {
      this.search = val;
    },
    findInSelectedRows(val){
      if(this.selectedRows.length > 0) {
        for(let i = 0; i < this.selectedRows.length; i += 1) {
          if(this.selectedRows[i]._id === val._id) {
            return i;
          }
        }
      return -1;
      } else {
        return -1;
      }
    },
    addToSelectedRows(val) {
      const exists = this.findInSelectedRows(val);
      if(exists >= 0) return;
      this.selectedRows.push(val);
    },
    deleteFromSelectedRows(val) {
      const exists = this.findInSelectedRows(val);
      if(exists < 0) return;
      this.selectedRows.splice(exists, 1);
    },
    toggle(val) {
      const exists = this.findInSelectedRows(val);
      if(exists > -1) {
        this.deleteFromSelectedRows(val);
      } else {
        this.addToSelectedRows(val);
      }
    },
    copyEmailsToClipboard(){
      let emails = "";
      const separator = ";";
      const lineFeed = "\n";
      for (let i = 0 ; i < this.selectedRows.length ; i += 1) {
        if(this.copyParentEmail) {
          emails += `"${this.selectedRows[i].parentFirstName} ${this.selectedRows[i].parentLastName} (PARENT) - ${this.selectedRows[i].gymName}"<${this.selectedRows[i].parentEmail}>${separator}${lineFeed}`
        }

        if(this.copyCoachEmail) {
          emails += `"${this.selectedRows[i].coachFirstName} ${this.selectedRows[i].coachLastName} (COACH) - ${this.selectedRows[i].gymName}"<${this.selectedRows[i].coachEmail}>${separator}${lineFeed}`
        }


        if(this.copyGymnastEmail) {
          emails += `"${this.selectedRows[i].athleteFirstName} ${this.selectedRows[i].athleteLastName} (ATHLETE) - ${this.selectedRows[i].gymName}"<${this.selectedRows[i].athleteEmail}>${separator}${lineFeed}`
        }
      }

      this.copyTextToClipboard(emails)
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
        if(successful == 'successful') {
          this.snackbar = true;
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      const that = this;
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
        that.snackbar = true;
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    async approveDeny(id, val) {
      await api.post("/approveDenyAthlete", {id, keyValue: {approved: val, approvedDeniedOn: (new Date())} });
      await this.getAthletes();
    },
    async fullPayment(id, val, dep) {
      let deposit = {};
      if(dep === false) {
        deposit = {
          deposit: true,
          depositOn: (new Date())
        }
      }
      await api.post("/fullPayment", {id, keyValue: {fullPayment: val, fullPaymentOn: (new Date()), ...deposit} });
      await this.getAthletes();
    },
    async deposit(id, val) {
      await api.post("/depositPayment", {id, keyValue: {deposit: val, depositOn: (new Date())} });
      await this.getAthletes();
    },
    goToProfileForCoach(id){
      this.$router.push(`/collegeshowcase/officialProfile?id=${id}`);
    },
    async getAthletes() {
      this.loading = true;
      const regsToInclude = [
      'High Performance Showcase ($500 | $510 Venmo)',
      'Acro & Tumbling ($375 | $385 Venmo)',
      'Bundle #1 [High Perf Showcase] + [Acro & Tumbling] - ($550 | $565 Venmo)',
      'Bundle #2 [All Levels Camp] + [Acro & Tumbling] - ($425 | $435 Venmo)',
      'All Levels - ($400 | $410 Venmo)',
    ];
      const {id} = this.$route.query;
      this.athlete = ((await api.post("/athlete")).data?.data|| []).filter((item) => {
        this.loading = false;
        return regsToInclude.includes(item.athleteRegisteredFor) && item.approved === true;
      });

    },
    isProfileComplete(item) {
      const events = ['vt_current','vt_working','ub_current','ub_working','bb_current','bb_working','fx_current','fx_working']
      let complete = true;
      if (item) {
        for (let i = 0; i < events.length; i++) {
          complete = complete && item[events[i]]?.length > 0;
        }
      }
      return complete && !!item.athleteInstagram && !!item.athleteYoutube && !!item.athleteGymStyle;
    },
    async saveChanges(keyValue) {
      this.athlete = (await api.post("/updateProfile", {id: this.athlete._id, keyValue})).data?.data?.[0] || {};
    }
  },
  computed: {
      sessions: function() {
        // ["Session 1","Session 2","Session 3","Session 4","Session 1 & 2","Session 1 & 3","Session 1 & 4","Session 1 , 2 & 4","Session 1 , 3 & 4"]
        const session1 = [...this.athlete].filter((item) => ["Session 1","Session 1 & 2","Session 1 & 3","Session 1 & 4","Session 1 , 2 & 4","Session 1 , 3 & 4"].includes(item.athleteSession));
        const session2 = [...this.athlete].filter((item) => ["Session 2","Session 1 & 2","Session 1 , 2 & 4"].includes(item.athleteSession));
        const session3 = [...this.athlete].filter((item) => ["Session 3","Session 1 & 3","Session 1 , 3 & 4"].includes(item.athleteSession));
        const session4 = [...this.athlete].filter((item) => ["Session 4","Session 1 & 4","Session 1 , 2 & 4","Session 1 , 3 & 4"].includes(item.athleteSession));
        return {
          session1,
          session2,
          session3,
          session4
        }
      },
  },
}
</script>

<style lang="scss">
.mini-profile-picture-container {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border: 2px solid #CCC;
  margin: 0.25rem;
  img {
    width: 100%;
  }
}
.v-data-table__mobile-row__cell {
  width: 100%;
  .mini-profile-picture-outer-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .mini-profile-picture-container {
      width: 150px;
      height: 150px;
    }
  }
}
.highlighted{
  background-color: #EEE;
  &:hover {
    background-color: #EEE !important;
  }
}

.not-highlighted{
  background-color: transparent;
  &:hover {
    background-color: transparent !important;
  }
}
  .action-btn {
    margin-right: .5rem;
    margin-bottom: .4rem;
  }
  .left-lined {
    border-left: 2px dotted #EEE;
    padding-left: 1.5rem;
  }

  .waiver-picture {
    width:90%;
    border-radius: 16px;
    border: 4px solid #DDD;
    overflow: hidden;
  }
  .offset-label .v-input .v-label--active{
    transform: translateX(-20%) translateX(-7.5px) translateY(-24px) scale(0.75) !important;
  }
  .x-small, .x-small .v-btn.v-size--x-small {
    font-size: 9px !important;
  }
  .v-data-table__wrapper {
    tr {
      padding-bottom: 1rem;
      td {
        padding:.5rem 0 .5rem 0!important;
        border-bottom: none !important;
        border-top: thin solid #DDD;
        
      }
    }
  }
  .expanded-region {
    td {
      border-top: none !important;
    }
  }
  .text-center .v-input__slot {
    justify-content: center;
  }
  tr.v-data-table__mobile-table-row {
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: .25rem;
    &:nth-child(even), .v-data-table__wrapper > table > tbody > tr:nth-child(even){
      background-color: #DDD;
    }
    &:hover {
      background-color: inherit;
    }
    .athleteGradYearPill {
      float: right;
    }
    .pill-reg-for {
      display: inline-block;
      margin-bottom: 0.25rem;
    }
  }
  .v-data-table__wrapper {
    font-family: Verdana;
    font-size: 0.9rem;
    & > table > tbody > tr {
      background-color: #FFF;
      &:hover {
        background-color: inherit;
      }
      &:nth-child(even){
        background-color: #DDD;
      }
    }
  }
  .athleteGradYearPill {
    padding: 0.125rem 0.25rem;
    font-size: 0.7rem;
    width: 60px;
    border-radius: 1rem;
    margin-left: 1rem;
    color: white;
    font-family: Verdana;
    text-align: center;
    float: center;
    &.y2025 {
      background-color: #ffb700;
    }
    &.y2026 {
      background-color: #5c3b69;
    }
    &.y2027 {
      background-color: #e64c9b;
    }
    &.y2028 {
      background-color: #46afbf;
    }
    &.y2029 {
      background-color: #5980a0;
    }
    &.y2030 {
      background-color: #ff0090;
    }
    &.y2031 {
      background-color: #ff8800;
    }
    &.y2032 {
      background-color: #575757;
    }
  }
  .small {
    font-size: 0.7rem !important;
  }
  .pill-reg-for {
    font-size: .7rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.75rem;
    color: white;
    margin-bottom: 0.25rem;
    text-align: center;
    min-width: 131px;
    
    &.pill-gym {
      background-color: #5980a0;
    }
    &.pill-al {
      background-color: #238500;
    }
    &.pill-at {
      background-color: #fca800;
    }
  }
  .committed-pill {
    font-size: 0.6rem;
    padding: 0.1rem 0.3rem;
    border-radius: 1rem;
    margin-top: 0.125rem;
    display: inline-block;
    &.committed {
      color: #ce2702;
      border: 1px solid #ce2702;
    }
    &.uncommitted {
      color: #288e00;
      border: 1px solid #288e00;
    }
  }
  .pill-reg-for-container {
    display: flex;
    flex-direction: column;
    flex-grow: 0 !important;
    align-content: stretch;
    align-items: self-end;
  }
  .athlete-gpa {
    border: 1px solid #CCC;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    color: #666;
    font-family: monospace;
    letter-spacing: 0px;
}
</style>