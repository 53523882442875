<template>
  <v-container class="px-10">
      <alert 
        :success="this.athlete.approved === true"
        :textSuccess="`<b>Congratulations</b> You have been accepted into our roster!`"
        :textError="`You have not yet been accepted into our roster. We update our roster several times during the day. Please check back later!`"
      />

      <alert 
        :success="this.athlete.deposit_paid === true "
        :textSuccess="`<b>This is good news!</b> We have received your deposit payment.`"
        :textError="`We have not yet received your deposit payment.`"
        :actionTextError="`Click here for instructions on how to make a payment`"
        :actionMethodError="()=> this.$router.push(`/downPayment?id=${this.athlete._id}`)"
        icon="mdi-currency-usd"
      />

      <alert 
        v-if="this.athlete.deposit_paid === true"
        :success="this.athlete.full_payment_paid === true"
        :textSuccess="`<b>This is great news!</b> We have received your full payment.`"
        :textError="`We have not yet received your remaining payment.`"
        :actionTextError="`Click here for instructions on how to make a payment`"
         :actionMethodError="()=> this.$router.push(`/fullPayment?id=${this.athlete._id}`)"
       icon="mdi-currency-usd"
      />

      <alert 
        :success="!!this.athlete.waiver_signed"
        :textSuccess="`<b>${this.athlete.waiver_signed_by}</b> signed the liability waiver on <b>${this.athlete.waiver_signed_on}</b>`"
        :actionTextSuccess="`Click to view waiver`"
        :actionMethodSuccess="()=> this.$router.push(`/waiverPreview?id=${this.athlete._id}`)"
        :textError="`You have not yet signed a liability waiver for <b>${this.athlete.athleteFirstName} ${this.athlete.athleteLastName}</b>`"
        :actionTextError="`Click to sign waiver`"
        :actionMethodError="()=> this.$router.push(`/waiver?id=${this.athlete._id}`)"
        icon="mdi-signature-freehand"
      />

      <alert 
        :success="!!this.athlete.profile_picture_id === true"
        :textSuccess="`You have successfully uploaded a profile picture`"
        :actionTextSuccess="`Click to see your profile picture`"
        :actionMethodSuccess="()=> this.$router.push(`/profilePicture?id=${this.athlete._id}`)"
        :textError="`You have not yet uploaded a profile picture`"
        :actionTextError="`Click to upload one`"
        :actionMethodError="()=> this.$router.push(`/profilePicture?id=${this.athlete._id}`)"
        icon="mdi-face-man-shimmer"

      />

      <alert 
        :success="this.isProfileComplete"
        :textSuccess="`You have Completed your gymnastics profile`"
        :actionTextSuccess="`Click to view or edit your gymnastics profile`"
        :actionMethodSuccess="()=>this.$router.push(`/gymnasticsProfile?id=${this.athlete._id}`)"
        :textError="`You have not yet completed your gymnastics profile`"
        :actionTextError="`Click to complete your profile`"
        :actionMethodError="()=>this.$router.push(`/gymnasticsProfile?id=${this.athlete._id}`)"
        icon="mdi-format-list-numbered"
      />

      <v-row class="mb-2 mt-8 full-width">
        <v-col class="grow"><h2> Athlete's info </h2></v-col>
        <v-col><v-btn  @click="() => this.$router.push(`/editRegistration?id=${this.athlete._id}`)" class="ml-5  float-right" color="info" outlined> Click here to edit the info below </v-btn></v-col>
      </v-row>
      <v-row class="mb-4">
        
        <v-col cols="12" xs="12" sm="12" class="pt-0 pb-0 mb-10 left-lined">
          <div v-if="!this.athlete.athleteCommitted">
            <b>This athlete is currently marked as uncommitted</b><br/>
          </div>
          <div v-else>
            <b>This athlete is currently marked as committed <span v-if="!!this.athlete.athleteCommittedTo">to {{this.athlete.athleteCommittedTo}}.</span></b><br/>
          </div>
          <small>If this information is incorrect, click the EDIT button above to correct this information</small>
        </v-col>
        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0 left-lined">
          <label-data-field label="Athlete's Name" :text="`${this.athlete.athleteFirstName} ${this.athlete.athleteLastName}`"/>
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined">
          <label-data-field label="Date of Birth" :text="this.athlete.athleteDob"/>
        </v-col>
        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0 left-lined">
          <label-data-field label="Gymnast's Email" :text="this.athlete.athleteEmail"/>
        </v-col>
      </v-row>

    <v-row class="mb-4">
      <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0 left-lined">
        <label-data-field label="Gym Name" :text="this.athlete.gymName"/>
      </v-col>

      <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined">
        <label-data-field label="Level" :text="this.athlete.athleteLevel"/>
      </v-col>

      <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined">
        <label-data-field label="Grad Year" :text="this.athlete.athleteGradYear"/>
      </v-col>
    </v-row>

     <v-row class="mt-5">
        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0 left-lined">
          <label-data-field label="Register For" :text="this.athlete.athleteRegisteredFor"/>
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined" v-if="athleteRegisteredFor == 'High Performance Showcase ($500 | $510 Venmo)' || athleteRegisteredFor == 'Bundle #2 [All Levels Camp] + [Acro & Tumbling] - ($425 | $435 Venmo)' || athleteRegisteredFor == 'Bundle #1 [High Perf Showcase] + [Acro & Tumbling] - ($550 | $565 Venmo)'">
          <label-data-field label="Leo Size" :text="this.athlete.athleteLeoSize"/>
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined" v-if=" athleteRegisteredFor == 'All Levels - ($400 | $410 Venmo)' || athleteRegisteredFor == 'High Performance Showcase ($500 | $510 Venmo)' || athleteRegisteredFor == 'Acro & Tumbling ($375 | $385 Venmo)' || athleteRegisteredFor == 'Bundle #2 [All Levels Camp] + [Acro & Tumbling] - ($425 | $435 Venmo)' || athleteRegisteredFor == 'Bundle #1 [High Perf Showcase] + [Acro & Tumbling] - ($550 | $565 Venmo)'">
          <label-data-field label="T-shirt Size" :text="this.athlete.athleteTeeSize"/>
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined" v-if="athleteRegisteredFor == 'Acro & Tumbling ($375 | $385 Venmo)' || athleteRegisteredFor == 'Bundle #2 [All Levels Camp] + [Acro & Tumbling] - ($425 | $435 Venmo)' || athleteRegisteredFor == 'Bundle #1 [High Perf Showcase] + [Acro & Tumbling] - ($550 | $565 Venmo)'">
          <label-data-field label="Fitted TankTop Size" :text="this.athlete.athleteTankSize"/>
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined" v-if="athleteRegisteredFor == 'Acro & Tumbling ($375 | $385 Venmo)' || athleteRegisteredFor == 'Bundle #2 [All Levels Camp] + [Acro & Tumbling] - ($425 | $435 Venmo)' || athleteRegisteredFor == 'Bundle #1 [High Perf Showcase] + [Acro & Tumbling] - ($550 | $565 Venmo)'">
          <label-data-field label="Fitted Shorts Size" :text="this.athlete.athleteShortSize"/>
        </v-col>
      </v-row>

      <v-row class="mb-4 mt-8">
        <h2 class="pt-5"> Parent | Guardian Details </h2>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined">
          <label-data-field label="Parent's First Name" :text="this.athlete.parentFirstName"/>
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0 left-lined">
          <label-data-field label="Parent's Last Name" :text="this.athlete.parentLastName"/>
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0 left-lined">    
          <label-data-field label="Parent's phone number" :text="this.athlete.parentPhoneNumber"/>
        </v-col>

        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0 left-lined">
          <label-data-field label="Parent's Email" :text="this.athlete.parentEmail"/>
        </v-col>
      </v-row> 

      <v-row class="mb-4 mt-8">
        <h2 class="pt-5 mx-0 px-0"> Coach Details </h2>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0 left-lined">
          <label-data-field label="Coach's First Name" :text="this.athlete.coachFirstName"/>
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0 left-lined">
          <label-data-field label="Coach's Last Name" :text="this.athlete.coachLastName"/>
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0 left-lined">    
          <label-data-field label="Coach's phone number" :text="this.athlete.coachPhoneNumber"/>
        </v-col>

        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0 left-lined">
          <label-data-field label="Coach's Email" :text="this.athlete.coachEmail"/>
        </v-col>
      </v-row>    


   <v-snackbar
      v-model="snackbar"
    >
      Your registration request was submitted successfully!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
      </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
import { Model } from "survey-vue";
import LabelDataField from "../../components/LabelDataField.vue"
import Alert from "../../components/Alert.vue"
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: { LabelDataField, Alert },
  data() {
    return {
      athlete: [],
      snackbar: false
    }
  },
  async mounted() {
    const {id, success} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
    if (success) {
      this.snackbar = true;
    }
  },
  methods: {
  },
  computed: {
    isProfileComplete: function() {
      const events = ['vt_current','vt_working','ub_current','ub_working','bb_current','bb_working','fx_current','fx_working']
      let complete = true;
      if (this.athlete) {
        for (let i = 0; i < events.length; i++) {
          complete = complete && this.athlete[events[i]]?.length > 0;
        }
      }
      return complete && !!this.athlete.athleteInstagram && !!this.athlete.athleteYoutube && !!this.athlete.athleteGymStyle;
    }
  }
}
</script>

<style lang="scss">
  .left-lined {
    border-left: 2px dotted #EEE;
    padding-left: 1.5rem;
  }
</style>